import { ReactComponent as PuzzleIcon } from "@/assets/icons/puzzle-icon.svg";
import { Button, Card, Image } from "@nextui-org/react";
import { useContext, useState } from "react";
import { StepContext } from "..";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { sendAmplitudeData } from "@/analytics";

export const PinExtention = () => {
  return (
    <div className="flex flex-col items-center gap-4">
      <div className="w-full">
        <h3 className="text-base font-normal flex gap-1">
          Lastly click <PuzzleIcon /> in the top right corner of your browser
          and pin
        </h3>
        <h3 className="text-base font-normal ">
          LoadConnect, to keep us visible
        </h3>
      </div>
      <Image
        src="/img/others/pin-extension.png"
        alt="Pin extension"
        width={400}
        height={347}
      />
      <ClickHere />
    </div>
  );
};

const ClickHere = () => {
  const { setAbleToFinish } = useContext(StepContext);
  const [visible, setVisible] = useState(true);

  const handleClick = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.onboardingDismissButtonClicked);
    setAbleToFinish(true);
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <Card className="fixed top-2 right-2 p-4 flex flex-col gap-4 rounded-xl">
      <div className="flex flex-col gap-2 items-center">
        <h3 className="text-base flex items-center gap-1">
          Click <PuzzleIcon />
          above to pin LoadConnect
        </h3>
        <Image
          src="/img/others/pin-extension2.png"
          alt="Pin extension"
          width={261}
          height={160}
        />
      </div>
      <Button size="sm" radius="lg" color="primary" onPress={handleClick}>
        Dismiss
      </Button>
    </Card>
  );
};
