import {
  totalInquiries,
  avgPostToContactTime,
  avgRate,
  dispatchGraph,
  dispatchList,
} from "@/server/cubev2.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getTotalInquiries = createAsyncThunk(
  "cubeAnalytics/getTotalInquiries",
  async (param: { dateStart: string; dateEnd: string; userIds: string[] }) => {
    const { dateStart, dateEnd, userIds } = param;
    const data = await totalInquiries({ dateStart, dateEnd, userIds });
    return data;
  }
);

export const getAvgPostToContactTime = createAsyncThunk(
  "cubeAnalytics/getAvgPostToContactTime",
  async (param: { dateStart: string; dateEnd: string; userIds: string[] }) => {
    const { dateStart, dateEnd, userIds } = param;
    return await avgPostToContactTime({ dateStart, dateEnd, userIds });
  }
);

export const getAvgRate = createAsyncThunk(
  "cubeAnalytics/getAvgRate",
  async (param: { dateStart: string; dateEnd: string; userIds: string[] }) => {
    const { dateStart, dateEnd, userIds } = param;
    return await avgRate({ dateStart, dateEnd, userIds });
  }
);

export const getDispatchGraph = createAsyncThunk(
  "cubeAnalytics/getDispatchGraph",
  async (param: { dateStart: string; dateEnd: string; userIds: string[] }) => {
    const { dateStart, dateEnd, userIds } = param;
    return await dispatchGraph({ dateStart, dateEnd, userIds });
  }
);

export const getDispatchList = createAsyncThunk(
  "cubeAnalytics/getDispatchList",
  async (param: { dateStart: string; dateEnd: string; userIds: string[] }) => {
    const { dateStart, dateEnd, userIds } = param;
    return await dispatchList({ dateStart, dateEnd, userIds });
  }
);

export const getOldTotalInquiries = createAsyncThunk(
  "cubeAnalytics/getOldTotalInquiries",
  async (param: { dateStart: string; dateEnd: string; userIds: string[] }) => {
    const { dateStart, dateEnd, userIds } = param;
    return await totalInquiries({ dateStart, dateEnd, userIds });
  }
);

export const getOldAvgPostToContactTime = createAsyncThunk(
  "cubeAnalytics/getOldAvgPostToContactTime",
  async (param: { dateStart: string; dateEnd: string; userIds: string[] }) => {
    const { dateStart, dateEnd, userIds } = param;
    return await avgPostToContactTime({ dateStart, dateEnd, userIds });
  }
);

export const getOldAvgRate = createAsyncThunk(
  "cubeAnalytics/getOldAvgRate",
  async (param: { dateStart: string; dateEnd: string; userIds: string[] }) => {
    const { dateStart, dateEnd, userIds } = param;
    return await avgRate({ dateStart, dateEnd, userIds });
  }
);

export const getOldDispatchList = createAsyncThunk(
  "cubeAnalytics/getOldDispatchList",
  async (param: { dateStart: string; dateEnd: string; userIds: string[] }) => {
    const { dateStart, dateEnd, userIds } = param;
    return await dispatchList({ dateStart, dateEnd, userIds });
  }
);
