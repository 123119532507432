import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
  cn,
} from "@nextui-org/react";
import React, { FC } from "react";

type Column = {
  key: string;
  label: string;
  align?: "start" | "center" | "end";
  allowsSorting?: boolean;
};

type Row = Record<string, string | number>;

interface Props {
  rows: Row[];
  columns: Column[];
  renderCell: (item: any, columnKey: React.Key) => JSX.Element;
  style?: React.CSSProperties;
  emptyText?: string;
  maxHeight?: string;
  isLoading?: boolean;
  bottomContent?: React.ReactNode;
}

export const DataTable: FC<Props> = ({
  rows,
  columns,
  style,
  renderCell,
  maxHeight,
  emptyText,
  isLoading,
  bottomContent,
}) => {
  const baseClassName = cn(
    "min-h-60 overflow-y-auto font-grotesk",
    maxHeight || "max-h-[calc(100vh_-_800px)]"
  );

  return (
    <Table
      isStriped
      isHeaderSticky
      aria-label="Enquiries table"
      style={style}
      classNames={{
        base: baseClassName,
        wrapper: "p-0",
        thead: "[&>tr]:first:shadow-none",
        th: "font-normal text-base text-gray-800",
        td: "text-gray-700 text-base",
        sortIcon: "text-primary-500 w-4 h-auto",
        emptyWrapper: "text-base",
      }}
      shadow="none"
      bottomContent={bottomContent}
    >
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody
        items={rows}
        isLoading={isLoading}
        loadingContent={<Spinner />}
        emptyContent={emptyText}
      >
        {(item) => (
          <TableRow key={item.key || JSON.stringify(item)}>
            {(columnKey) => (
              <TableCell>{renderCell(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
