import { Badge, Tab, Tabs } from "@nextui-org/react";
import { Outlet, useLocation } from "react-router-dom";

import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

const selectedKeyMap: Record<string, string> = {
  emailTemplate: "emailTemplate",
  emailTemplateEditor: "emailTemplate",
  email: "email",
};

const Settings = () => {
  const { accountVerified } = useSelector(
    (state: RootState) => state.authReducer.value
  );
  const location = useLocation();

  const pathName = location.pathname.split("/")[1];
  const selectedKey = selectedKeyMap[pathName];

  return (
    <div className="px-8">
      <Tabs
        size="lg"
        variant="underlined"
        selectedKey={selectedKey}
        classNames={{
          base: "w-full border-b",
          tab: "text-lg font-bold",
          tabList: "p-0 gap-6 min-w-40",
          cursor: "w-full",
        }}
      >
        <Tab key="emailTemplate" href="/emailTemplate" title="Template"></Tab>
        <Tab
          key="email"
          href="/email"
          title={
            <Badge
              isInvisible={accountVerified}
              content=""
              color="danger"
              size="sm"
            >
              Email
            </Badge>
          }
        ></Tab>
      </Tabs>
      <Outlet />
    </div>
  );
};

export default Settings;
