import {
  CubeDispatch,
  CubeDispatchGraph,
  CubeInquiries,
  UserRole,
} from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import {
  getAvgPostToContactTime,
  getAvgRate,
  getDispatchGraph,
  getDispatchList,
  getOldAvgPostToContactTime,
  getOldAvgRate,
  getOldDispatchList,
  getOldTotalInquiries,
  getTotalInquiries,
} from "../async/getCubeAnalyticsData";
import { today, DateValue } from "@internationalized/date";

interface CubeAnalyticsState {
  userType: UserRole | null;
  totalInquiries: CubeInquiries | null;
  totalInquiriesLoading: boolean;
  avgPostToContactTime: number;
  avgPostToContactTimeLoading: boolean;
  avgRate: number;
  avgRateLoading: boolean;
  dispatchList: CubeDispatch[];
  dispatchListLoading: boolean;
  dispatchGraph: CubeDispatchGraph[];
  dispatchGraphLoading: boolean;

  oldTotalInquiries: CubeInquiries | null;
  oldTotalInquiriesLoading: boolean;
  oldAvgPostToContactTime: number;
  oldAvgPostToContactTimeLoading: boolean;
  oldAvgRate: number;
  oldAvgRateLoading: boolean;
  oldDispatchList: CubeDispatch[];
  oldDispatchListLoading: boolean;

  selectedUsers: string[];
  startDate: DateValue;
  endDate: DateValue;
}

const initialState: CubeAnalyticsState = {
  userType: null,
  totalInquiries: null,
  totalInquiriesLoading: false,
  avgPostToContactTime: 0,
  avgPostToContactTimeLoading: false,
  avgRate: 0,
  avgRateLoading: false,
  dispatchList: [],
  dispatchListLoading: false,
  dispatchGraph: [],
  dispatchGraphLoading: false,

  oldTotalInquiries: null,
  oldTotalInquiriesLoading: false,
  oldAvgPostToContactTime: 0,
  oldAvgPostToContactTimeLoading: false,
  oldAvgRate: 0,
  oldAvgRateLoading: false,
  oldDispatchList: [],
  oldDispatchListLoading: false,

  selectedUsers: [],
  startDate: today("America/New_York"),
  endDate: today("America/New_York"),
};

const cubeAnalyticsSlice = createSlice({
  name: "cubeAnalytics",
  initialState,
  reducers: {
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTotalInquiries.pending, (state) => {
      state.totalInquiriesLoading = true;
    });
    builder.addCase(getTotalInquiries.fulfilled, (state, action) => {
      state.totalInquiries = action.payload;
      state.totalInquiriesLoading = false;
    });
    builder.addCase(getTotalInquiries.rejected, (state, action) => {
      state.totalInquiriesLoading = false;
    });
    builder.addCase(getAvgPostToContactTime.pending, (state) => {
      state.avgPostToContactTimeLoading = true;
    });
    builder.addCase(getAvgPostToContactTime.fulfilled, (state, action) => {
      state.avgPostToContactTime = action.payload;
      state.avgPostToContactTimeLoading = false;
    });
    builder.addCase(getAvgPostToContactTime.rejected, (state, action) => {
      state.avgPostToContactTimeLoading = false;
    });
    builder.addCase(getAvgRate.pending, (state) => {
      state.avgRateLoading = true;
    });
    builder.addCase(getAvgRate.fulfilled, (state, action) => {
      state.avgRate = action.payload;
      state.avgRateLoading = false;
    });
    builder.addCase(getAvgRate.rejected, (state, action) => {
      state.avgRateLoading = false;
    });
    builder.addCase(getDispatchGraph.pending, (state) => {
      state.dispatchGraphLoading = true;
    });
    builder.addCase(getDispatchGraph.fulfilled, (state, action) => {
      state.dispatchGraph = action.payload;
      state.dispatchGraphLoading = false;
    });
    builder.addCase(getDispatchGraph.rejected, (state, action) => {
      state.dispatchGraphLoading = false;
    });
    builder.addCase(getDispatchList.pending, (state) => {
      state.dispatchListLoading = true;
    });
    builder.addCase(getDispatchList.fulfilled, (state, action) => {
      state.dispatchList = action.payload;
      state.dispatchListLoading = false;
    });
    builder.addCase(getDispatchList.rejected, (state, action) => {
      state.dispatchListLoading = false;
    });
    builder.addCase(getOldTotalInquiries.pending, (state) => {
      state.oldTotalInquiriesLoading = true;
    });
    builder.addCase(getOldTotalInquiries.fulfilled, (state, action) => {
      state.oldTotalInquiries = action.payload;
      state.oldTotalInquiriesLoading = false;
    });
    builder.addCase(getOldTotalInquiries.rejected, (state, action) => {
      state.oldTotalInquiriesLoading = false;
    });
    builder.addCase(getOldAvgPostToContactTime.pending, (state) => {
      state.oldAvgPostToContactTimeLoading = true;
    });
    builder.addCase(getOldAvgPostToContactTime.fulfilled, (state, action) => {
      state.oldAvgPostToContactTime = action.payload;
      state.oldAvgPostToContactTimeLoading = false;
    });
    builder.addCase(getOldAvgPostToContactTime.rejected, (state, action) => {
      state.oldAvgPostToContactTimeLoading = false;
    });
    builder.addCase(getOldAvgRate.pending, (state) => {
      state.oldAvgRateLoading = true;
    });
    builder.addCase(getOldAvgRate.fulfilled, (state, action) => {
      state.oldAvgRate = action.payload;
      state.oldAvgRateLoading = false;
    });
    builder.addCase(getOldAvgRate.rejected, (state, action) => {
      state.oldAvgRateLoading = false;
    });
    builder.addCase(getOldDispatchList.pending, (state) => {
      state.oldDispatchListLoading = true;
    });
    builder.addCase(getOldDispatchList.fulfilled, (state, action) => {
      state.oldDispatchList = action.payload;
      state.oldDispatchListLoading = false;
    });
    builder.addCase(getOldDispatchList.rejected, (state, action) => {
      state.oldDispatchListLoading = false;
    });
  },
});

export const { setSelectedUsers, setStartDate, setEndDate } =
  cubeAnalyticsSlice.actions;

export default cubeAnalyticsSlice.reducer;
