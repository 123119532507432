import { Select, SelectItem, SelectProps } from "@nextui-org/react";
import { FC } from "react";
import { ISelectOption } from "@/types";

export interface FitlersSelectProps extends Omit<SelectProps, "children"> {
  label?: string;
  options: ISelectOption[];
  icon?: React.ReactNode;
  isClearable?: boolean;
}

export const FitlersSelect: FC<FitlersSelectProps> = (props) => {
  const {
    label,
    options,
    icon,
    className,
    selectedKeys,
    onSelectionChange,
    multiple,
    ...rest
  } = props;

  const cn = className || `max-w-56`;

  return (
    <Select
      {...rest}
      className={cn}
      classNames={{
        innerWrapper: "!bg-default-50 min-w-44",
        trigger: "!bg-default-50",
      }}
      selectionMode={multiple ? "multiple" : "single"}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
      disableSelectorIconRotation
      startContent={<SelectStartContent icon={icon} label={label} />}
      aria-label={label}
    >
      {options.map((option) => (
        <SelectItem key={option.id}>{option.name || "-"}</SelectItem>
      ))}
    </Select>
  );
};

const SelectStartContent: FC<{ label?: string; icon?: React.ReactNode }> = ({
  label,
  icon,
}) => {
  return (
    <div className="flex items-center justify-center gap-1 leading-5">
      {icon}
      {label}
    </div>
  );
};
