import ReactDOM from "react-dom/client";
import App from "./app";
import Providers from "./providers";

import "./styles/index.css";
import "@/assets/fonts/FoundersGrotesk/stylesheet.css";

import "./config/mapbox.config";
import { initAmplitude } from "./analytics";

initAmplitude();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Providers>
    <App />
  </Providers>
);
