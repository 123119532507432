import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Progress,
} from "@nextui-org/react";
import { ScrollShadow } from "@nextui-org/scroll-shadow";
import { createContext, FC, useEffect, useMemo, useState } from "react";

import { ReactComponent as UserIcon } from "@/assets/icons/user-icon.svg";
import { ReactComponent as EmailIcon } from "@/assets/icons/email-icon.svg";
import { ReactComponent as PinIcon } from "@/assets/icons/pin-icon.svg";

import { PersonalInformation } from "./components/PersonalInformation";
import { BrandEmail } from "./components/BrandEmail";
import { Step } from "./components/Step";
import { PinExtention } from "./components/PinExtention";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { postUserInfo } from "@/server/auth.service";
import { setUserInfo } from "@/redux/slices/user-info-slice";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { getCookie } from "react-use-cookie";

const ONBOARDING_STEPS = [
  {
    id: "personal",
    title: "Create account",
    icon: <UserIcon />,
    content: <PersonalInformation />,
  },
  {
    id: "email",
    title: "Edit template",
    icon: <EmailIcon />,
    content: <BrandEmail />,
  },
  {
    id: "pin",
    title: "Pin and Start",
    icon: <PinIcon />,
    content: <PinExtention />,
  },
];

export const StepContext = createContext({
  step: 0,
  setAbleToFinish: (value: boolean) => {},
  userType: "",
  setUserType: (value: any) => {},
  trucksAmount: "",
  setTrucksAmount: (value: any) => {},
  loadboards: [""],
  setLoadboards: (value: any) => {},
});

export const OnboardingModal: FC<{ show: boolean; onClose: () => void }> = ({
  show,
  onClose,
}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.userInfoReducer);
  const [userType, setUserType] = useState<string>(userInfo?.userType);
  const [trucksAmount, setTrucksAmount] = useState<string>(
    userInfo?.trucksAmount
  );
  const [loadboards, setLoadboards] = useState<string[]>(userInfo?.loadboards);
  const [currentStep, setStep] = useState(0);
  const [ableToFinish, setAbleToFinish] = useState(false);

  const nextStep = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.onboardingNextButtonClicked);
    setStep((prev) => prev + 1);
  };
  const prevStep = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.onboardingBackButtonClicked);
    setStep((prev) => prev - 1);
  };

  const progress = (100 / ONBOARDING_STEPS.length) * (currentStep + 1);

  const isLastStep = currentStep === ONBOARDING_STEPS.length - 1;

  const handleNextClick = async () => {
    if (currentStep === 0) {
      const data = await postUserInfo({
        userType,
        trucksAmount,
        loadboards,
        source: getCookie("source") || "",
      });
      dispatch(setUserInfo(data));
    }
    if (isLastStep) {
      sendAmplitudeData(AMPLITUDE_EVENTS.onboardingFinished);
      onClose();
    } else {
      nextStep();
    }
  };

  const providerValue = useMemo(
    () => ({
      step: currentStep,
      setAbleToFinish,
      userType,
      setUserType,
      trucksAmount,
      setTrucksAmount,
      loadboards,
      setLoadboards,
    }),
    [currentStep, loadboards, trucksAmount, userType]
  );

  useEffect(() => {
    sendAmplitudeData(AMPLITUDE_EVENTS.onboardingStarted);
  }, []);

  return (
    <Modal
      size="5xl"
      radius="lg"
      classNames={{
        base: "rounded-xl w-[868px] overflow-hidden",
        closeButton: "absolute !top-4 !right-4",
      }}
      isDismissable={false}
      closeButton={<></>}
      isOpen={show}
      scrollBehavior="inside"
    >
      <ModalContent>
        <StepContext.Provider value={providerValue}>
          <Progress value={progress} size="sm" />
          <ModalHeader className="flex justify-between items-center text-2xl font-medium text-gray-900">
            Welcome to LoadConnect 🎉
          </ModalHeader>
          <ModalBody>
            <div className="h-[540px] p-0 py-2 flex gap-6">
              <div className="w-52 flex flex-col">
                {ONBOARDING_STEPS.map((step, index) => (
                  <Step
                    key={step.id}
                    title={step.title}
                    icon={step.icon}
                    isDone={index < currentStep}
                    isActive={index === currentStep}
                    withDash={index !== 0}
                  />
                ))}
              </div>
              <Divider orientation="vertical" />
              <ScrollShadow hideScrollBar className="w-[620px] h-full">
                {ONBOARDING_STEPS[currentStep].content}
              </ScrollShadow>
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between items-center">
            {currentStep > 0 ? (
              <Button variant="bordered" onPress={prevStep}>
                Back
              </Button>
            ) : (
              <div></div>
            )}
            <Button
              color="primary"
              isDisabled={isLastStep && !ableToFinish}
              onPress={handleNextClick}
            >
              {isLastStep ? "Finish" : "Next"}
            </Button>
          </ModalFooter>
        </StepContext.Provider>
      </ModalContent>
    </Modal>
  );
};
