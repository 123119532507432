import { Button } from "@nextui-org/button";
import { Input } from "@nextui-org/input";

import EditorWrapper from "@/components/editor";
import { Template } from "@/types";
import { defaultValue, defaultValueBroker } from "./default-value";
import {
  mergetags_list,
  mergetags_list_broker,
} from "@/components/editor/config";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const TemplateViewer = ({
  template,
  onChange,
  errorMessage,
  onLoad,
}: {
  template: Template;
  onLoad?: any;
  onChange: Function;
  errorMessage?: any;
}) => {
  const features = useSelector(
    (state: RootState) => state.authReducer.value.features
  );
  const userType = useSelector(
    (state: RootState) => state.userInfoReducer.userType
  );

  const isCCEnabled = features.includes("cc");
  const isBroker = useMemo(() => userType === "broker", [userType]);

  const loadDefault = () => {
    onChange({
      ...template,
      template: isBroker ? defaultValueBroker.body : defaultValue.body,
      subject: isBroker ? defaultValueBroker.subject : defaultValue.subject,
      cc: "",
    });
  };

  const handleChange = (e: any) => {
    onChange({ ...template, [e.target.name]: e.target.value || "" });
  };

  return (
    <>
      <div className="grid grid-cols-5 gap-4">
        <Input
          labelPlacement="outside"
          variant="bordered"
          className="relative col-span-3"
          type="text"
          id="name"
          name="name"
          placeholder="Template Name"
          label="Template Name"
          value={template.name}
          onChange={handleChange}
          classNames={{ label: "text-base text-gray-900 font-semibold" }}
          isInvalid={errorMessage["name"] !== ""}
          errorMessage={errorMessage["name"]}
        />

        <div className="ml-5 flex flex-col gap-4 row-span-4 col-span-2">
          <h2 className="text-base font-semibold text-gray-500 dark:text-white col-span-2">
            Allowed Variables
          </h2>
          {isBroker && (
            <>
              <div>
                <p className="text-gray-500 mb-2 font-semibold">
                  Broker (Posts):
                </p>
                <ul className="max-w-md space-y-2 text-gray-500 dark:text-gray-400">
                  {mergetags_list_broker.map((tag) => (
                    <li key={tag.value}>
                      {"{{" + tag.value + "}}"} : <b>{tag.title}</b>
                    </li>
                  ))}
                </ul>
              </div>
              <hr />
            </>
          )}
          <div>
            <p className="text-gray-500 mb-2 font-semibold">Carrier (Loads):</p>
            <ul className="max-w-md space-y-2 text-gray-500 dark:text-gray-400">
              {mergetags_list.map((tag) => (
                <li key={tag.value}>
                  {"{{" + tag.value + "}}"} : <b>{tag.title}</b>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {isCCEnabled && (
          <Input
            labelPlacement="outside"
            variant="bordered"
            className="relative col-span-3 col-start-1"
            type="text"
            id="cc"
            name="cc"
            placeholder="CC"
            label="CC"
            value={template.cc}
            onChange={handleChange}
            classNames={{ label: "text-base text-gray-900 font-semibold" }}
          />
        )}

        <Input
          value={template.subject}
          onChange={handleChange}
          labelPlacement="outside"
          variant="bordered"
          className="relative col-span-3"
          type="text"
          id="subject"
          name="subject"
          placeholder="Subject"
          label="Subject"
          classNames={{
            inputWrapper: "pr-0",
            label: "text-base text-gray-900 font-semibold",
          }}
          isInvalid={errorMessage["subject"] !== ""}
          errorMessage={errorMessage["subject"]}
          endContent={
            <Button
              variant="solid"
              disableAnimation
              onPress={loadDefault}
              className="rounded-l-none rounded-r-md data-[pressed=true]:scale-100"
            >
              Default Value
            </Button>
          }
        />
        <label className="text-base text-gray-500 font-semibold flex flex-col gap-2 col-span-3">
          Body
          <EditorWrapper
            value={template.template}
            onChange={(val: string) => onChange({ ...template, template: val })}
            onLoad={onLoad}
          />
        </label>
      </div>
    </>
  );
};

export default TemplateViewer;
