import { Avatar, cn } from "@nextui-org/react";
import { FC, ReactNode } from "react";

import { ReactComponent as DoneIcon } from "@/assets/icons/done-icon.svg";

export const Step: FC<{
  title: string;
  icon: ReactNode;
  isActive?: boolean;
  isDone?: boolean;
  withDash?: boolean;
}> = ({ title, icon, isActive = false, withDash = true, isDone = false }) => {
  const textClassName = cn("text-lg font-normal", {
    "text-brand-700": isActive || isDone,
    "text-gray-500": !isActive && !isDone,
  });

  const dashLineClassName = cn("ml-5 my-1 h-16 border-l border-dashed", {
    "border-brand-700": isActive || isDone,
    "border-gray-300": !isActive && !isDone,
  });

  const avatarClassName = cn("w-10 h-10", {
    "bg-brand-700/5 border-none": isActive,
    "bg-transparent border border-brand-700": isDone,
    "bg-transparent border border-gray-300": !isActive && !isDone,
  });

  return (
    <div className="flex flex-col">
      {withDash && <div className={dashLineClassName} />}

      <div className="flex gap-4 items-center cursor-pointer">
        <Avatar
          classNames={{
            base: avatarClassName,
            icon: textClassName,
          }}
          size="sm"
          icon={isDone ? <DoneIcon /> : icon}
        />
        <span className={textClassName}>{title}</span>
      </div>
    </div>
  );
};
