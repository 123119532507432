export const mergetags_list = [
  {
    value: "company",
    title: "Company",
  },
  {
    value: "do",
    title: "Deadhead",
  },
  {
    value: "dest",
    title: "Destination",
  },
  {
    value: "email",
    title: "Email",
  },
  {
    value: "origin",
    title: "Origin",
  },
  {
    value: "avail",
    title: "Pickup Date",
  },
  {
    value: "rate",
    title: "Rate",
  },
  {
    value: "ref",
    title: "Reference Number",
  },
  {
    value: "trip",
    title: "Trip",
  },
  {
    value: "truck",
    title: "Truck type",
  },
  {
    value: "weight",
    title: "Weight",
  },
  {
    value: "length",
    title: "Length",
  },
  {
    value: "tripDO",
    title: "Trip + Deadhead",
  },
];

export const mergetags_list_broker = [
  {
    value: "postedLoadTruckType",
    title: "Posted Load Truck Type",
  },
  {
    value: "postedLoadTruckLoad",
    title: "Posted Load Truck Load",
  },
  {
    value: "postedLoadOriginDestination",
    title: "Posted Load Origin Destination",
  },
  {
    value: "postedLoadOrigin",
    title: "Posted Load Origin",
  },
  {
    value: "postedLoadDestination",
    title: "Posted Load Destination",
  },
  {
    value: "postedLoadAvailabilityDate",
    title: "Posted Load Availability Date",
  },
];

export const toolbar =
  "undo redo | blocks | bold italic underline forecolor | bullist numlist | lineheight align | link image | mergetags | code removeformat | preview";

export const plugins = [
  // "advcode",
  "advlist",
  // "advtemplate",
  "anchor",
  "autolink",
  "charmap",
  "code",
  "editimage",
  // "emoticons",
  "fullscreen",
  "help",
  "image",
  "inlinecss",
  "insertdatetime",
  "link",
  // "linkchecker",
  "lists",
  "mergetags",
  "preview",
  "quickbars",
  "searchreplace",
  "table",
  "visualblocks",
  "wordcount",
];
