import {
  AnalyticsRequest,
  CubeInquiries,
  CubeDispatchGraph,
  CubeDispatch,
} from "@/types";
import cube from "@cubejs-client/core";
const cubeApi = cube(
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MzUyNzYwMTN9.9Juro0MRGPLqqVAkxlaHOjs6uCk7udaosYnXB0li8Mc",
  {
    apiUrl: "https://gross-lizard.aws-us-east-1.cubecloudapp.dev/cubejs-api/v1",
  }
);

export function getDispatchAnalytics(analyticsRequest: AnalyticsRequest) {
  totalInquiries(analyticsRequest);
  avgPostToContactTime(analyticsRequest);
  avgRate(analyticsRequest);
  dispatchGraph(analyticsRequest);
  dispatchList(analyticsRequest);
}

export async function totalInquiries(
  analyticsRequest: AnalyticsRequest
): Promise<CubeInquiries> {
  const query = {
    timezone: "America/New_York",
    dimensions: ["load_event_table.type_of_contact"],
    timeDimensions: [
      {
        dimension: "load_event_table.contact_time",

        dateRange: [analyticsRequest.dateStart, analyticsRequest.dateEnd],
      },
    ],
    filters: [
      {
        values: analyticsRequest.userIds,
        member: "load_event_table.user_id",
        operator: "equals",
      },
    ],
    measures: ["load_event_table.count"],
    order: {
      "load_event_table.contact_time": "asc",
    },
  };

  const resultSet = await cubeApi.load(query as any);
  const data = resultSet.tablePivot({
    x: ["load_event_table.contact_time.day"],
    y: ["load_event_table.type_of_contact", "measures"],
    fillMissingDates: true,
  });

  const emailsSent = Number(
    data.length > 0 ? data[0]["EMAIL,load_event_table.count"] : "0"
  );
  const phoneCalls = Number(
    data.length > 0 ? data[0]["PHONE,load_event_table.count"] : "0"
  );
  return {
    emailSent: emailsSent,
    phoneCall: phoneCalls,
    totalInquiries: emailsSent + phoneCalls,
  };
}

export async function avgPostToContactTime(
  analyticsRequest: AnalyticsRequest
): Promise<number> {
  const query = {
    timezone: "America/New_York",
    dimensions: [],
    timeDimensions: [
      {
        dimension: "load_event_table.contact_time",
        dateRange: [analyticsRequest.dateStart, analyticsRequest.dateEnd],
      },
    ],
    filters: [
      {
        values: analyticsRequest.userIds,
        member: "load_event_table.user_id",
        operator: "equals",
      },
    ],
    measures: ["load_event_table.avg_post_to_contact_time"],
    order: {
      "load_event_table.contact_time": "asc",
    },
  };
  const resultSet = await cubeApi.load(query as any);
  const data = resultSet.tablePivot({
    x: ["load_event_table.contact_time.day"],
    y: ["measures"],
    fillMissingDates: true,
  });
  const avgPostToContactTime = Number(
    data[0]["load_event_table.avg_post_to_contact_time"]
  );
  return avgPostToContactTime;
}

export async function avgRate(
  analyticsRequest: AnalyticsRequest
): Promise<number> {
  const query = {
    timezone: "America/New_York",
    dimensions: [],
    timeDimensions: [
      {
        dimension: "load_event_table.contact_time",
        dateRange: [analyticsRequest.dateStart, analyticsRequest.dateEnd],
      },
    ],
    filters: [
      {
        values: analyticsRequest.userIds,
        member: "load_event_table.user_id",
        operator: "equals",
      },
    ],
    measures: ["load_event_table.avg_rate"],
    order: {},
  };

  const resultSet = await cubeApi.load(query as any);
  const data = resultSet.tablePivot();
  const avgRate = Number(data[0]["load_event_table.avg_rate"]);
  return avgRate;
}

export async function dispatchGraph(
  analyticsRequest: AnalyticsRequest
): Promise<CubeDispatchGraph[]> {
  const query = {
    timezone: "America/New_York",
    timeDimensions: [
      {
        dimension: "load_event_table.contact_time",
        granularity: "day",
        dateRange: [analyticsRequest.dateStart, analyticsRequest.dateEnd],
      },
    ],
    measures: ["load_event_table.count"],
    filters: [
      {
        values: analyticsRequest.userIds,
        member: "load_event_table.user_id",
        operator: "equals",
      },
    ],
    dimensions: [
      "load_event_table.type_of_contact",
      "load_event_table.user_id",
    ],
  };
  const resultSet = await cubeApi.load(query as any);
  const data = resultSet.tablePivot({
    x: ["load_event_table.contact_time.day"],
    y: [
      "load_event_table.user_id",
      "load_event_table.type_of_contact",
      "measures",
    ],
    fillMissingDates: true,
  });
  const transformedData = data.map((item: any) => {
    const date = item["load_event_table.contact_time.day"];
    const userData = [];
    for (const userId of analyticsRequest.userIds) {
      const emailSent = Number(
        item[`${userId},EMAIL,load_event_table.count`] || 0
      );
      const phoneCall = Number(
        item[`${userId},PHONE,load_event_table.count`] || 0
      );
      const totalInquiries = emailSent + phoneCall;
      userData.push({ userId, emailSent, phoneCall, totalInquiries });
    }
    return { date, userData };
  });

  return transformedData;
}

export async function dispatchList(
  analyticsRequest: AnalyticsRequest
): Promise<CubeDispatch[]> {
  const query = {
    timezone: "America/New_York",
    timeDimensions: [
      {
        dimension: "load_event_table.contact_time",
        dateRange: [analyticsRequest.dateStart, analyticsRequest.dateEnd],
      },
    ],
    measures: ["load_event_table.count"],
    filters: [
      {
        values: analyticsRequest.userIds,
        member: "load_event_table.user_id",
        operator: "equals",
      },
    ],
    dimensions: [
      "load_event_table.user_id",
      "load_event_table.type_of_contact",
    ],
  };
  const resultSet = await cubeApi.load(query as any);
  const data = resultSet.tablePivot({
    x: ["load_event_table.user_id"],
    y: ["load_event_table.type_of_contact", "measures"],
    fillMissingDates: true,
  });
  const transformedData = data.map((item: any) => {
    const userId = item["load_event_table.user_id"];
    const emailSent = Number(item["EMAIL,load_event_table.count"] || 0);
    const phoneCall = Number(item["PHONE,load_event_table.count"] || 0);
    const totalInquiries = emailSent + phoneCall;
    return { userId, emailSent, phoneCall, totalInquiries };
  });

  return transformedData;
}

export async function statsBySource(analyticsRequest: AnalyticsRequest) {
  const query = {
    timezone: "America/New_York",
    timeDimensions: [
      {
        dimension: "load_event_table.contact_time",
        dateRange: [analyticsRequest.dateStart, analyticsRequest.dateEnd],
      },
    ],
    measures: ["load_event_table.count"],
    filters: [
      {
        values: analyticsRequest.userIds,
        member: "load_event_table.user_id",
        operator: "equals",
      },
    ],
    dimensions: ["load_event_table.source"],
  };

  const resultSet = await cubeApi.load(query as any);
  const data = resultSet.tablePivot({
    x: ["load_event_table.source"],
    y: ["measures"],
    fillMissingDates: true,
  });
  return data;
}
