import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Template } from "./Template";

import {
  defaultValue,
  defaultValueBroker,
} from "@/components/template-viewer/default-value";
import { Button, Card, Input, Select, SelectItem } from "@nextui-org/react";

import { ReactComponent as AddIcon } from "@/assets/icons/add-icon.svg";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { getTemplates, updateTemplate } from "@/server/template.service";
import ToastMessage from "@/components/toast";
import Loading from "@/components/loading";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { debounce } from "lodash";
import { StepContext } from "..";
import {
  mergetags_list,
  mergetags_list_broker,
} from "@/components/editor/config";

interface IModeSetters {
  setCreateMode: () => void;
  setEditMode: () => void;
  setIdleMode: () => void;
}

interface IContentProps {
  template: ITemplate;
  email: string;
  modeSetters: IModeSetters;
  onSubmit: (data: ITemplate, name?: string) => void;
}

export interface ITemplate {
  subject: string;
  body: string;
  variables: {
    value: string;
    title: string;
  }[];
}

export const BrandEmail = () => {
  const [template, setTemplate] = useState<any>();
  const { email } = useSelector((state: RootState) => state.authReducer.value);

  const [mode, setMode] = useState<"idle" | "edit" | "create">("idle");

  const setCreateMode = () => setMode("create");
  const setEditMode = () => setMode("edit");
  const setIdleMode = useCallback(() => setMode("idle"), []);

  const { userType } = useContext(StepContext);

  useEffect(() => {
    getTemplates().then((resp) => {
      let defaultTemplate = resp.find((item) => item.name === "Default");

      if (userType === "broker") {
        if (!defaultTemplate) {
          defaultTemplate = resp[0];
        }
        setTemplate({
          ...defaultTemplate,
          subject: defaultValueBroker.subject,
          body: defaultValueBroker.body,
          variables: mergetags_list_broker,
        });
      } else if (defaultTemplate) {
        setTemplate({
          ...defaultTemplate,
          body: defaultTemplate.template,
          variables: mergetags_list,
        });
      } else if (resp.length > 0) {
        setTemplate({
          ...resp[0],
          body: resp[0].template,
          variables: mergetags_list,
        });
      } else {
        setTemplate(defaultValue);
      }
    });
  }, [userType]);

  const handleSubmit = useCallback(
    async (data: ITemplate, name?: string) => {
      try {
        const response = await updateTemplate({
          id: template.id,
          cc: "",
          labels: [],
          name: name || "Default",
          template: data.body,
          subject: data.subject,
        });

        setTemplate({
          ...response,
          body: response.template,
          variables: [],
        });
        ToastMessage("success", "Update Success");
        setIdleMode();
      } catch (error) {
        ToastMessage("error", "Error while updating");
      }
    },
    [setIdleMode, template]
  );

  if (!template) return <Loading />;

  return (
    <div className="w-full h-full flex flex-col gap-3">
      {mode === "idle" ? (
        <>
          <h3 className="text-base font-normal">
            Feel free to use our prepared email template, or create your own
            from scratch
          </h3>
          <IdleContent
            email={email}
            template={template}
            modeSetters={{
              setCreateMode,
              setEditMode,
              setIdleMode,
            }}
            onSubmit={handleSubmit}
          />
        </>
      ) : mode === "create" ? (
        <CreateContent
          email={email}
          template={template}
          modeSetters={{
            setCreateMode,
            setEditMode,
            setIdleMode,
          }}
          onSubmit={handleSubmit}
        />
      ) : (
        <EditContent
          email={email}
          template={template}
          modeSetters={{
            setCreateMode,
            setEditMode,
            setIdleMode,
          }}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

const IdleContent: FC<IContentProps> = ({ template, modeSetters, email }) => {
  const { setCreateMode, setEditMode, setIdleMode } = modeSetters;

  const handleChangeButtonClick = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.onboardingEditTemplateButtonClicked);
    setEditMode();
  };

  const handleCreateButtonClick = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.onboardingCreateTemplateButtonClicked);
    setCreateMode();
  };

  return (
    <>
      <Select
        isDisabled
        label="From"
        defaultSelectedKeys={[email]}
        className="w-full"
        labelPlacement="outside"
        radius="lg"
        classNames={{
          base: "opacity-70",
        }}
        selectorIcon={<></>}
      >
        <SelectItem key={email}>{email}</SelectItem>
      </Select>
      <Template
        mode="idle"
        editMode={false}
        onChangeClick={handleChangeButtonClick}
        onCancelClick={setIdleMode}
        data={template}
      />
      <Card
        shadow="none"
        radius="lg"
        className="p-3 rounded-xl flex-row justify-between items-center bg-brand-700/5 shrink-0"
      >
        <p className="text-sm font-semibold text-brand-900">
          Want to create a new template from scratch?
        </p>
        <Button
          startContent={<AddIcon />}
          radius="lg"
          color="primary"
          variant="bordered"
          size="sm"
          className="bg-white"
          onPress={handleCreateButtonClick}
        >
          Create
        </Button>
      </Card>
    </>
  );
};

const CreateContent: FC<IContentProps> = ({ modeSetters, onSubmit }) => {
  const { userType } = useContext(StepContext);
  const [error, setError] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [template] = useState<ITemplate>({
    subject: "",
    body: "",
    variables: userType === "broker" ? mergetags_list_broker : mergetags_list,
  });
  const { setEditMode, setIdleMode } = modeSetters;

  const resetError = useCallback(() => setError(""), []);

  const handleSubmit = useCallback(
    (template: ITemplate) => {
      if (!name) {
        setError("Template name is required");
        return;
      }
      sendAmplitudeData(
        AMPLITUDE_EVENTS.onboardingCreateTemplateSaveButtonClicked
      );
      onSubmit(template, name);
    },
    [name, onSubmit]
  );

  const handleCancel = useCallback(() => {
    sendAmplitudeData(
      AMPLITUDE_EVENTS.onboardingCreateTemplateCancelButtonClicked
    );
    setIdleMode();
  }, [setIdleMode]);

  const handleNameChange = debounce((value: string) => {
    sendAmplitudeData(AMPLITUDE_EVENTS.onboardingCreateTemplateNameChanged);
    setName(value);
  }, 500);

  return (
    <>
      <Input
        label="Template name"
        placeholder="Create template name"
        classNames={{
          label: "!text-gray-500",
          inputWrapper:
            "!bg-white text-gray-700 border border-gray-200 rounded-xl shadow-none",
          helperWrapper:
            "position-absolute top-0 right-0 mt-1 mr-1 text-xs text-red-500",
        }}
        labelPlacement="outside"
        onValueChange={handleNameChange}
        isRequired
        errorMessage={error}
        isInvalid={!!error}
        onClick={resetError}
      />
      <Template
        mode="create"
        editMode={true}
        onChangeClick={setEditMode}
        onCancelClick={handleCancel}
        data={template}
        onSubmit={handleSubmit}
      />
    </>
  );
};

const EditContent: FC<IContentProps> = ({
  template,
  modeSetters,
  email,
  onSubmit,
}) => {
  const { setEditMode, setIdleMode } = modeSetters;

  const handleSubmit = useCallback(
    (template: ITemplate) => {
      sendAmplitudeData(
        AMPLITUDE_EVENTS.onboardingEditTemplateSaveButtonClicked
      );
      onSubmit(template);
    },
    [onSubmit]
  );

  const handleCancel = useCallback(() => {
    sendAmplitudeData(
      AMPLITUDE_EVENTS.onboardingEditTemplateCancelButtonClicked
    );
    setIdleMode();
  }, [setIdleMode]);

  return (
    <>
      <Select
        isDisabled
        label="From"
        defaultSelectedKeys={[email]}
        className="w-full"
        labelPlacement="outside"
        radius="lg"
        classNames={{
          base: "opacity-70",
        }}
        selectorIcon={<></>}
      >
        <SelectItem key={email}>{email}</SelectItem>
      </Select>
      <Template
        mode="edit"
        editMode={true}
        onChangeClick={setEditMode}
        onCancelClick={handleCancel}
        data={template}
        onSubmit={handleSubmit}
      />
    </>
  );
};
