import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { PlusIcon } from "@/components/icons";
import Notification from "@/components/notification";
import ToastMessage from "@/components/toast";
import { RootState } from "@/redux/store";
import { deleteTemplate, getTemplates } from "@/server/template.service";
import { Template } from "@/types";
import { Button } from "@nextui-org/button";
import { useDisclosure } from "@nextui-org/modal";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import TemplateList from "./template-list";

const EmailTemplate = () => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [load, setLoad] = useState<boolean>(false);

  const { onOpen: openNotification, onClose, isOpen } = useDisclosure();

  const { features } = useSelector(
    (state: RootState) => state.authReducer.value
  );
  const isMultipleTemplatesEnabled = features.includes("email");

  useEffect(() => {
    setLoad(true);
    getTemplates()
      .then((resp) => {
        setTemplates(resp);
        setLoad(false);
      })
      .catch(() => setLoad(false));
  }, []);

  const onDelete = async (data: Template) => {
    if (templates.length <= 1) return openNotification();

    await deleteTemplate(data.id);
    setTemplates(templates.filter((item) => item.id !== data.id));
    ToastMessage("success", "Success deleting a template");
  };

  return (
    <div className="flex-grow py-6 text-gray-900">
      <Helmet>
        <title>LoadConnect Email Templates Dashboard</title>
      </Helmet>
      <h2>My Templates</h2>
      <p className="my-4 text-base">
        In this section, you can generate customized email templates that you
        will send. These templates are linked to the extension.
      </p>
      {isMultipleTemplatesEnabled && (
        <Button
          onPress={() => {
            sendAmplitudeData(
              AMPLITUDE_EVENTS.dashboardNewTemplateButtonClicked
            );
          }}
          as={Link}
          className="data-[pressed=true]:scale-100 hover:bg-primary-600"
          to="/emailTemplateEditor"
          aria-label="Template Editor Action"
          startContent={<PlusIcon />}
          color="primary"
        >
          Add new Template
        </Button>
      )}

      <TemplateList
        loading={load}
        templates={templates}
        className="mt-7"
        onDelete={onDelete}
      />
      <Outlet />
      <Notification
        isOpen={isOpen}
        onClose={onClose}
        title="You can't delete this template"
        content="You will need to have at least one active template"
      />
    </div>
  );
};

export default EmailTemplate;
