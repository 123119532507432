import { mergetags_list, mergetags_list_broker } from "../editor/config";

export const defaultValue = {
  subject: "Load from {{origin}} to {{dest}}",
  body:
    "<p>Hello, team!</p><p><br></p><p>Can we get more info pls about the load by this ref</p>" +
    "<p>number&nbsp;{{ref}}&nbsp;and what is the best rate?</p><p><br></p><p>MC: 00000</p>" +
    "<p>Dispatch Team at&nbsp;</p><p>Alex Daniel</p><p>Number: +1234567890123</p>",
  variables: mergetags_list,
};

export const defaultValueBroker = {
  subject:
    "Load Available from {{postedLoadOrigin}} to {{postedLoadDestination}}",
  body:
    "<p>Hello, team!</p>" +
    "<p>We have load available for {{postedLoadTruckType}} from {{postedLoadOrigin}} to {{postedLoadDestination}}<p>" +
    "<p>which is available at {{postedLoadAvailabilityDate}} and suggest the rate?</p>",
  variables: [...mergetags_list, ...mergetags_list_broker],
};
